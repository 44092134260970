.integration-content {
  margin-top: -40px;
}

.container {
  margin-top: inherit;
}

.access-keys-input {
  align-items: center !important;
  text-align: end !important;
}

.unset-padding {
  padding: unset !important;
}
.accesskeys {
  .mt-3 {
    width: 50%;
  }
  .access-keys-input {
    .button {
      min-width: 91px;
    }
  }
}

.modal {
  all: unset;
}

.key-label {
  font-size: large;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  background-color: #f8f8f9;
  padding: 10px;
  word-break: break-all;
  position: relative;
}

.display-inline-block {
  display: inline-block;
}

.display-initial {
  display: initial;
}