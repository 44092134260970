.head-email {
    float: right;
    position: absolute;
    right: 0;
    margin-right: 5rem;
}

.acc-input {
    // margin-left: 2.8rem;
    width: 50%;
}

.key-container{
    margin-left: 0.8rem;
    h6{
        margin-top:1rem;
    }
}

.configure-step1 {
    background-image: url(../../assets/images/configure2fa_step1.png);
    height: 127px !important;
    background-repeat: no-repeat;
}

.configure-step3 {
    background-image: url(../../assets/images/configure2fa_step3.png);
    height: 127px !important;
    background-repeat: no-repeat;
}

p.mini, span.mini {
    font-size: .8rem;
    line-height: 1rem;
    font-family: 'Helvetica Neue',Helvetica,'Segoe UI',Tahoma,Arial,sans-serif;
}

.modal {
    all: unset;
  }